.Dropdown-control {
    cursor: pointer;
    font-style: normal!important;
    font-size: 16px!important;
    background-color: transparent!important;
    border: none!important;
    color: rgba(0, 0, 0, 0.70)!important;
    text-transform: uppercase!important;
    padding-right:25px!important;
    margin-bottom: -10px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
  }
  .Dropdown-menu {
    font-style: normal;
    font-size: 16px!important;
    max-height: 300px!important;
    
  }
  .Dropdown-arrow {
    border-color: rgba(0, 0, 0, 0.70) transparent transparent!important;
    margin-top: 3px;
   
  }
  .is-open .Dropdown-arrow {
    border-color: transparent transparent rgba(0, 0, 0, 0.70)!important;
  }
  .Dropdown-control:hover {
    box-shadow: 0 0px 0 white!important;
  }
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    top: 30px
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: rgba(0, 0, 0, 0.70);
    height: 3px!important;
  }
  
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    top: 20px!important;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: rgba(0, 0, 0, 0.70);
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%!important;
    margin-top: -30px;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #DEE2E1;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    z-index: 999;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #DEE2E1;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: rgba(0, 0, 0, 0.70);
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }