@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@638&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'NeutralFace';
  src: url('./fonts/NeutralFace.otf') format('opentype');
  font-display: auto;
  font-weight: 400;
  font-style: normal; 
}

@media (min-width: 1280px) {
  .textproducts {
    writing-mode: vertical-lr;
  }
}

.buttoncars{
  display: inline-flex;
  padding: 14px 30px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: #000;
}

.butcar{
  border-radius: 2.364px;
  background: #D14507;
}

body.menu-open {
  overflow: hidden;
  max-width: 100%;
}
body.menu-closed {
  max-width: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.textcolor{
  color: rgba(0, 0, 0, 0.70);
}

.inter{
  font-family: "Inter", sans-serif;
}

.neutral{
  font-family: "NeutralFace", sans-serif;
}
.colorline{
  background: rgba(0, 0, 0, 0.20); 
}

.fade-in {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in.show {
  opacity: 1;
  transform: translateX(0);
}
.imgoffer {
  transition: transform 0.5s ease
}

.imgoffer.zoom {
  transform: scale(1.2);
}
.formfooter{
  border: 1px solid rgba(0, 0, 0, 0.20);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #000000B2;
  padding-left: 30px; 
  border-radius: 0%;
}

.formfooter::placeholder {
  color: #000000B2;
}

.buttonfooter{
  display: inline-flex;
  padding: 14px 30px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: #000;
  width: 127px;
  height: 52px;
  cursor: pointer;
}